import { Route, Routes } from 'react-router';
import ThemeCustomization from './themes';

import { routes } from './routes';
import { HelmetProvider } from 'react-helmet-async';

const App = () => {
  return (
    <HelmetProvider>
      <ThemeCustomization>
        <Routes>
          {routes.map((props, index) => {
            if (props.childrenRoutes && props.childrenRoutes.length > 0) {
              return <Route key={index} {...props}>
                {props.childrenRoutes.map((childrenProps, index) => {
                  if (childrenProps.isPublic) {
                    return <Route key={index} path={childrenProps.path} {...childrenProps} />;
                  }

                  return <Route key={index} path={childrenProps.path} {...childrenProps} />
                }
                )}
              </Route>;
            }

            return <Route key={index} {...props} />;
          })}
        </Routes>
      </ThemeCustomization>
    </HelmetProvider>
  );
};

export default App;
