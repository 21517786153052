import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import { useSelector as useAppSelector } from 'react-redux';

import menu from 'store/menu';

const reducer = combineReducers({ menu });

const store = configureStore({ reducer });

const { dispatch } = store;
const useSelector = useAppSelector;

export { dispatch, useSelector, store };