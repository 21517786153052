import { lazy } from 'react';

import Loadable from '../components/Loadable';
import CommonLayout from 'layout/CommonLayout';

const PagesLanding = Loadable(lazy(() => import('../pages/landing')));
const PagesContact = Loadable(lazy(() => import('../pages/contact-us')));
const PagesServicesTM = Loadable(lazy(() => import('../pages/services-tm')));
const PagesServicesBus = Loadable(lazy(() => import('../pages/services-bus')));
const PagesServicesPF = Loadable(lazy(() => import('../pages/services-pf')));
const PagesAboutUs = Loadable(lazy(() => import('../pages/about-us')));
const PagesQuestions = Loadable(lazy(() => import('../pages/questions')));
const PagesTerms = Loadable(lazy(() => import('../pages/terms')));
const PagesPrivacy = Loadable(lazy(() => import('../pages/privacy')));
const PagesOthers = Loadable(lazy(() => import('../pages/others')));
const PagesCookies = Loadable(lazy(() => import('../pages/cookies')));

const MainRoutes = {
  path: '/',
  exact: true,
  element: <CommonLayout />,
  isPublic: false,
  childrenRoutes: [
    {
      path: '/',
      exact: true,
      element: <PagesLanding />,
      isPublic: true
    },
    {
      path: '/contact-us',
      exact: true,
      element: <PagesContact />,
      isPublic: true
    },
    {
      path: '/Rovinieta-autoutilitara-transport-marfa-0-3.5-T',
      exact: true,
      element: <PagesServicesTM />,
      isPublic: true
    },
    {
      path: 'Rovinieta-microbus-autobuz',
      exact: true,
      element: <PagesServicesBus />,
      isPublic: true
    },
    {
      path: '/Rovinieta-persoane-fizice',
      exact: true,
      element: <PagesServicesPF />,
      isPublic: true
    },
    {
      path: '/Rovinieta-despre-noi',
      exact: true,
      element: <PagesAboutUs />,
      isPublic: true
    },
    {
      path: '/Rovinieta-intrebari-frecvente',
      exact: true,
      element: <PagesQuestions />,
      isPublic: true
    },
    {
      path: '/Rovinieta-termeni-si-conditii',
      exact: true,
      element: <PagesTerms />,
      isPublic: true
    },
    {
      path: '/Rovinieta-politica-de-confidentialitate',
      exact: true,
      element: <PagesPrivacy />,
      isPublic: true
    },
    {
      path: '/others',
      exact: true,
      element: <PagesOthers />,
      isPublic: true
    },
    {
      path: '/Rovinieta-politica-de-utilizare-cookies',
      exact: true,
      element: <PagesCookies />,
      isPublic: true
    }
  ]
};

export default MainRoutes;
